<template>
  <div class="leaderboard container">
    <div class="my-3">
      <h3 class="mb-3">{{ $t('label.leaderboard') }}</h3>
      <div class="table-responsive">
        <table class="table">
          <colgroup>
            <col class="col-place">
            <col class="col-name">
            <col class="col-point">
            <col class="col-win">
            <col class="col-lose">
            <col class="col-draw">
            <col v-for="tb in tieBreaks" :key="tb.key" :class="`col-tb col-tb-${tb.key}`" />
          </colgroup>
          <tr>
            <th>{{ $t('label.place') }}</th>
            <th>{{ $t('label.name') }}</th>
            <th>{{ $t('label.point') }}</th>
            <th>{{ $t('label.win') }}</th>
            <th>{{ $t('label.lose') }}</th>
            <th>{{ $t('label.draw') }}</th>
            <th v-for="tb in tieBreaks" :key="tb.key">
              {{ tb.path && $t(tb.path) || tb.name }}
            </th>
          </tr>
          <tr v-for="(p, idx) in leaders" :key="p.seq" :class="{ dropped: p.isDropped }">
            <td>{{ idx + 1 }}</td>
            <td class="cell-name">{{ p.name }}</td>
            <td>{{ p.point }}</td>
            <td>{{ p.win }}</td>
            <td>{{ p.lose }}</td>
            <td>{{ p.draw }}</td>
            <td v-for="tb in tieBreaks" :key="tb.key">
              {{ p[tb.key] | dataFilter(tb.format) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Leaderboard',
  components: {
  },
  computed: {
    leaders() {
      return this.$store.getters['event/leaderboard']
    },
    tieBreaks() {
      return this.$store.state.event.rule.tieBreaksEnabled.slice(1)
    },
  },
  methods: {
  },
  filters: {
    dataFilter(value, format) {
      switch(format) {
      case 'rate':
        return value.toFixed(3)
      default:
        return value
      }
    }
  },
}
</script>
<style scoped>
.table th:not(:nth-child(2)),
.table td:not(:nth-child(2)) {
  text-align: center;
}

.table .col-win,
.table .col-lose,
.table .col-draw {
  width: 3rem;
}
.table .col-place,
.table .col-point {
  width: 8rem;
}
.table .col-tb {
  width: 4rem;
}

.table .cell-name {
  word-break: keep-all;
}

.table .dropped {
  color: silver;
}
.table .dropped .cell-name::before {
  content: '(Dropped) ';
}
</style>
<i18n>
{
  "en": {
    "label": {
      "place": "Place",
      "point": "Point"
    }
  },
  "ja": {
    "label": {
      "place": "順位",
      "point": "点数",
      "win": "勝",
      "lose": "敗",
      "draw": "分"
    }
  }
}
</i18n>
